// modules/directory-ipb/style/scheduleStyles.js

export const scheduleRowStyle = {
    stack: {
        paddingY: 0.5,
    },
    typography: {
        display: "flex",
        alignItems: "center",
        marginRight: 1,
    },
    iconButton: {
        padding: "4px",
    },
};

export const deleteAllHoursStyle = {
    "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
    },
    textAlign: "right",
};
