export const colorFont = {
    color: "#691C32",
    fontWeight: 600,
    fontSize: 15,
};

export const selectHourStyle = {
    backgroundColor: "white",
    height: "48px",
    "& .MuiSelect-select": colorFont,
};
