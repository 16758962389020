import React from "react";
import CustomDialog from "widgets/component/CustomDialog";

const Dialogs = ({
    dialogOpen,
    setDialogOpen,
    successDialogOpen,
    setSuccessDialogOpen,
    responseMessage,
    handleClose,
    warningsDialogOpen,
    setWarningsDialogOpen,
    cleanIsEditStores,
    cleanIsRegisteredStores,
}) => (
    <>
        <CustomDialog
            title="Tienda ya registrada"
            content="Esta tienda ya está registrada. Por favor, verifica los datos."
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            fullWidth
            maxWidth="sm"
            extraButtons={[
                {
                    label: "Aceptar",
                    variant: "contained",
                    onClick: () => setDialogOpen(false),
                },
            ]}
        />
        <CustomDialog
            title=""
            content={
                responseMessage === "Success"
                    ? "Su registro se ha realizado con éxito"
                    : responseMessage
            }
            open={successDialogOpen}
            onClose={() => {
                setSuccessDialogOpen(false);
                handleClose();
                cleanIsEditStores();
            }}
            fullWidth
            maxWidth="sm"
            extraButtons={[
                {
                    label: "Aceptar",
                    variant: "contained",
                    onClick: () => {
                        setSuccessDialogOpen(false);
                        handleClose();
                        cleanIsEditStores();
                    },
                },
            ]}
        />
        <CustomDialog
            title=""
            content={responseMessage}
            open={warningsDialogOpen}
            onClose={() => {
                setWarningsDialogOpen(false);
                cleanIsRegisteredStores();
            }}
            fullWidth
            maxWidth="sm"
            extraButtons={[
                {
                    label: "Aceptar",
                    variant: "contained",
                    onClick: () => {
                        setWarningsDialogOpen(false);
                        cleanIsRegisteredStores();
                    },
                },
            ]}
        />
    </>
);

export default Dialogs;
