import { useEffect, useState } from "react";

export const useSchedule = ({
    setOpeningHours,
    openingHours,
    dataRowEdit,
    setValue,
}) => {
    const [open, setOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);

    useEffect(() => {
        if (dataRowEdit) {
            setValue("openingHours", dataRowEdit.opening_hours);
            setOpeningHours(dataRowEdit.opening_hours);
        }
    }, [dataRowEdit, setValue, setOpeningHours]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleDeleteClick = (week_day) => {
        setSelectedDay(week_day);
        setConfirmOpen(true);
    };

    const handleConfirmDelete = () => {
        if (selectedDay !== null) {
            handleDeleteHour(selectedDay);
            setConfirmOpen(false);
            setSelectedDay(null);
        }
    };

    const handleDeleteHour = (week_day) => {
        const updatedHours = openingHours.filter(
            (hour) => hour.week_day !== week_day
        );
        setOpeningHours(updatedHours);
        setValue("openingHours", updatedHours);
    };

    const handleDeleteAllHours = () => {
        setOpeningHours([]);
        setValue("openingHours", []);
    };

    const handleAddHour = (schedule) => {
        if (schedule.length === 0) {
            handleClose();
            return;
        }

        const converdDay = schedule.map(
            ({ week_day, openingHour, closingHour, is24Hours }) => ({
                week_day,
                ...(!is24Hours
                    ? {
                          opening_time: openingHour,
                          closing_time: closingHour,
                      }
                    : {}),
                is_24_hours: is24Hours,
            })
        );

        const updatedOpeningHours = [...openingHours];
        converdDay.forEach((newDay) => {
            const existingIndex = updatedOpeningHours.findIndex(
                (day) => day.week_day === newDay.week_day
            );
            if (existingIndex !== -1) {
                updatedOpeningHours[existingIndex] = newDay;
            } else {
                updatedOpeningHours.push(newDay);
            }
        });

        updatedOpeningHours.sort((a, b) => a.week_day - b.week_day);

        const updatedDataRow = {
            ...dataRowEdit,
            opening_hours: updatedOpeningHours,
        };

        setOpeningHours(updatedOpeningHours);
        setValue("openingHours", updatedOpeningHours);
        handleClose();

        return updatedDataRow;
    };

    const disabledDay = openingHours.map(({ week_day }) => week_day);
    return {
        open,
        confirmOpen,
        selectedDay,
        handleOpen,
        handleClose,
        handleDeleteClick,
        handleConfirmDelete,
        handleDeleteHour,
        handleDeleteAllHours,
        handleAddHour,
        disabledDay,
    };
};
