import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Chip } from "@mui/material";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";

export const useIpbDirectoryModeration = ({
    ipbDirectoryModerationListingAction,
    responseIpbDirectoryModerationListing,
    cleanIpbDirectoryModerationListingAction,
    ipbDirectoryModerationDetailgAction,
    cleanIpbDirectoryModerationDetailAction,
    responseIpbDirectoryModerationDetail,
    handleStoreModerationAction,
    handleStoreModerationClean,
    responseIpbDirectoryModeration,
}) => {
    const { payload } = responseIpbDirectoryModerationListing || {};
    const { response } = responseIpbDirectoryModerationDetail || {};
    const { directory_stores, pagination } = payload || {};

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [isDialogOpenDetails, setIsDialogOpenDetails] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [action, setAction] = useState(null);
    const [error, setError] = useState(false);
    const [mappedRows, setMappedRows] = useState([]);
    const [isCustomDialogOpen, setIsCustomDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [dialogTitle, setDialogTitle] = useState("");
    const [confirmAction, setConfirmAction] = useState(() => () => {});
    const [dialogButtonLabel, setDialogButtonLabel] = useState("");
    const [dialogButtonColor, setDialogButtonColor] = useState("");
    const [rejectionReason, setRejectionReason] = useState("");
    const [isRejecting, setIsRejecting] = useState(false);
    const [dialogLoading, setDialogLoading] = useState(false);
    const [isOpenSuccesOrError, setOpenSuccesOrError] = useState(null);

    //useEffect to handle the response of the detail action

    useEffect(() => {
        if (responseIpbDirectoryModeration?.status === 200) {
            setOpenSuccesOrError(true);
        } else {
            setOpenSuccesOrError(true);
            setDialogMessage(
                "Error al cargar los detalles de la transferencia."
            );
        }
    }, [responseIpbDirectoryModeration]);

    useEffect(() => {
        if (response?.response?.status > 200) {
            setIsDialogOpenDetails(true);
            setDialogMessage("Error");
            setDialogMessage(response?.response?.data?.detail);
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [response]);

    useEffect(() => {
        setLoading(true);
        ipbDirectoryModerationListingAction(page, size, searchParams)
            .then(() => setLoading(false))
            .catch(() => {
                setLoading(false);
            });

        return () => {
            cleanIpbDirectoryModerationListingAction();
        };
    }, [
        page,
        size,
        searchParams,
        ipbDirectoryModerationListingAction,
        cleanIpbDirectoryModerationListingAction,
    ]);

    useEffect(() => {
        if (responseIpbDirectoryModerationListing?.status === 200) {
            setLoading(false);
        } else if (
            responseIpbDirectoryModerationListing?.payload?.response?.status >
            200
        ) {
            setDialogMessage(
                responseIpbDirectoryModerationListing?.payload?.response?.data
                    ?.detail
            );
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [responseIpbDirectoryModerationListing]);

    const onSubmit = (data) => {
        if (!data) {
            setErrorMessage("Los datos del formulario no son válidos.");
            return;
        }

        const {
            from_date = "",
            to_date = "",
            authorization_code = "",
            masked_pan_termination = "",
        } = data;

        const areDatesProvided = from_date !== "" && to_date !== "";
        const isAuthorizationOrCardProvided =
            authorization_code !== "" || masked_pan_termination !== "";

        if (areDatesProvided || isAuthorizationOrCardProvided) {
            setLoading(true);
            setPage(1);
            const filteredData = Object.fromEntries(
                Object.entries(data).filter(([value]) => value !== "")
            );
            setSearchParams(filteredData);
            setErrorMessage("");
        } else {
            setErrorMessage(
                "Por favor, complete al menos uno de los siguientes campos: autorización, tarjeta o ambas fechas."
            );
        }
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangePageSize = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setSize(newSize);
        setPage(1);
    };

    useEffect(() => {
        if (directory_stores) {
            const updatedRows = directory_stores.map((row) => ({
                ...row,
                id: `${row.id}`,
            }));
            setMappedRows(updatedRows);
        }
    }, [directory_stores]);
    const handleIdButtonClick = (id) => {
        setIsDialogOpenDetails(true);
        if (id) {
            setIsDialogOpenDetails(true);
            ipbDirectoryModerationDetailgAction(id)
                .then(() => setLoading(false))
                .catch(() => {
                    setDialogMessage("Error");
                    setDialogMessage(
                        "Error al cargar los detalles de la transferencia."
                    );
                    setIsDialogOpen(true);
                    setLoading(false);
                });

            return () => {
                cleanIpbDirectoryModerationDetailAction();
            };
        }
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setIsDialogOpenDetails(false);
        cleanIpbDirectoryModerationDetailAction();
        handleStoreModerationClean();
        setOpenSuccesOrError(false);
    };

    const handleOpenPopUp = (action) => {
        setAction(action);
    };

    const openCustomDialog = (
        title,
        content,
        action,
        buttonLabel,
        buttonColor
    ) => {
        setDialogTitle(title);
        setDialogContent(content);
        setConfirmAction(() => action);
        setDialogButtonLabel(buttonLabel);
        setDialogButtonColor(buttonColor);
        setIsCustomDialogOpen(true);
    };

    const handleConfirm = async (data) => {
        setDialogLoading(true);
        await confirmAction(data);
        setDialogLoading(false);
        setIsCustomDialogOpen(false);
        setRejectionReason("");
        reset();
    };

    const handleRejectStoreWithConfirmation = () => {
        setIsRejecting(true);
        openCustomDialog(
            "Confirmar Rechazo",
            <>
                <p>
                    ¿Está seguro de que desea rechazar la tienda{" "}
                    {response?.store_name}?
                </p>
            </>,
            handleRejectStore,
            "Rechazar tienda",
            "#D63030"
        );
    };

    const handleAcceptStoreWithConfirmation = () => {
        setIsRejecting(false);
        openCustomDialog(
            "Confirmar Aceptación",
            `¿Deseas aceptar la tienda ${response?.store_name}?`,
            handleAcceptStore,
            "Aceptar tienda",
            "#1E9B61"
        );
    };

    const getStatusColor = (paymentStatus) => {
        switch (paymentStatus) {
            case "Aprobado":
                return "green";
            case "Rechazada":
                return "red";
            case "Pendiente":
                return "Orange";
            default:
                return "purple";
        }
    };

    const translationMap = {
        Requested: "Transacción registrada",
        Success: "Transacción exitosa",
        Fail: "Falló al procesar el pago",
        Review: "En espera de revisión",
        Error: "Error al procesar el pago",
    };

    const Columns = [
        {
            field: " ",
            headerName: "",
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Button onClick={() => handleIdButtonClick(params.row.id)}>
                    {"Ver detalle"}
                </Button>
            ),
        },

        {
            field: "external_id",
            headerName: "ID externo",
            width: 200,
            align: "right",
            headerAlign: "center",
        },
        {
            field: "store_name",
            headerName: "Nombre de la tienda",
            width: 200,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "review_status",
            headerName: "Aprobación",
            width: 220,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Chip
                    label={
                        translationMap[params.row.review_status] ||
                        params.row.review_status
                    }
                    style={{
                        backgroundColor: getStatusColor(
                            params.row.review_status
                        ),
                        color: "white",
                    }}
                />
            ),
        },
        {
            field: "state",
            headerName: "Estado",
            width: 250,
            headerAlign: "center",
            align: "center",
        },

        {
            field: "municipality",
            headerName: "Municipio",
            width: 250,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "created",
            headerName: "Fecha alta",
            width: 250,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                return convertToMexicoTime(params.row.created);
            },
        },
        {
            field: "omv_name",
            headerName: "OMV",
            width: 250,
            headerAlign: "center",
            align: "center",
        },
    ];

    const handleRejectStore = async (data) => {
        // Lógica para rechazar la tienda
        await handleStoreModerationAction({
            storeId: response?.id,
            reason: data.rejectionReason,
            status_id: 3,
        });
        setRejectionReason("");
        ipbDirectoryModerationListingAction(page, size, searchParams);
        reset();
    };

    const handleAcceptStore = async () => {
        // Lógica para aceptar la tienda
        await handleStoreModerationAction({
            storeId: response?.id,
            status_id: 2,
        });
        ipbDirectoryModerationListingAction(page, size, searchParams);
        setRejectionReason("");
        reset();
    };

    return {
        handleRejectStore: handleRejectStoreWithConfirmation,
        handleAcceptStore: handleAcceptStoreWithConfirmation,
        register,
        handleSubmit,
        errors,
        onSubmit,
        loading,
        page,
        size,
        handleChangePage,
        pagination: {
            ...pagination,
            handleChangePage,
            handleChangePageSize,
        },
        directory_stores,
        mappedRows,
        Columns,
        isDialogOpen,
        setIsDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        setErrorMessage,
        setError,
        error,
        handleOpenPopUp,
        setLoading,
        action,
        isCustomDialogOpen,
        dialogContent,
        dialogTitle,
        handleConfirm,
        setIsCustomDialogOpen,
        dialogButtonLabel,
        dialogButtonColor,
        rejectionReason,
        setRejectionReason,
        dialogLoading,
        control,
        isOpenSuccesOrError,
        setOpenSuccesOrError,
        isRejecting,
        reset,
    };
};
