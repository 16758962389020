import {
    CircularProgress,
    FormControl,
    MenuItem,
    Select,
    Stack,
} from "@mui/material";
import { colorFont, selectHourStyle } from "./selectHourStyleMui";
import { STORE_HOURS } from "../../data/store-hours";
import { useState } from "react";

export const SelectHour = ({
    initialHour,
    prefix,
    nameSelect,
    isDisabled,
    onChange,
}) => {
    const [hour, setHour] = useState(initialHour);

    const handleChangeHour = (event) => {
        setHour(event.target.value);
        onChange(event.target.value);
    };

    return (
        <>
            {STORE_HOURS.length > 0 ? (
                <FormControl fullWidth>
                    <Select
                        name={nameSelect}
                        value={hour}
                        defaultValue={hour}
                        size="small"
                        sx={selectHourStyle}
                        onChange={handleChangeHour}
                        disabled={isDisabled}
                    >
                        {STORE_HOURS?.map(({ value, id }) => (
                            <MenuItem key={id} value={value} sx={colorFont}>
                                {prefix}: {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <Stack
                    width={"100%"}
                    justifyContent={"center"}
                    direction={"row"}
                >
                    <CircularProgress size={30} color="inherit" />
                </Stack>
            )}
        </>
    );
};
