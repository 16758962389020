import axiosTokenInstance from "services/api/backoffice";
import {
    ZIPCODE_ADDRESS_STORE,
    ZIPCODE_ADDRESS_STORE_ERROR,
} from "_models/redux/types";

// Acción para verificar si una tienda existe
export const zipCodeAddressStoreAction = (zipCode) => async (dispatch) => {
    console.log("params (FormData)", zipCode);

    try {
        const response = await axiosTokenInstance.get(
            `/api/sepomex/zipcodes/${zipCode}/address`
        );

        const { data, status } = response || {};

        // Despachar los datos de la respuesta
        dispatch({
            type: ZIPCODE_ADDRESS_STORE,
            payload: data || {},
            status: status || {},
            method: "get",
        });
    } catch (e) {
        // Manejo de errores
        dispatch({
            type: ZIPCODE_ADDRESS_STORE_ERROR,
            payload: e || {},
        });
    }
};
