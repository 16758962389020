import React, { useEffect } from "react";
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from "@mui/material";
import { useStoreAddress } from "../hooks/useStoreAddress";

const StoreAddress = ({
    register,
    errors,
    dataRowEdit,
    responseZipCode,
    handleZipcodeSearch,
    setValue,
}) => {
    const {
        state,
        municipality,
        neighborhood,
        handleStateChange,
        handleMunicipalityChange,
        handleNeighborhoodChange,
    } = useStoreAddress({ setValue, responseZipCode, dataRowEdit });

    useEffect(() => {
        if (dataRowEdit) {
            setValue("postalCode", dataRowEdit.zipcode);
            setValue("state", dataRowEdit.state);
            setValue("municipality", dataRowEdit.municipality);
            setValue("neighborhood", dataRowEdit.neighborhood);
            setValue("street", dataRowEdit.address);
            setValue("reference", dataRowEdit.address_reference);
        }
    }, [dataRowEdit, setValue]);

    return (
        <>
            <TextField
                label="Código postal"
                variant="outlined"
                fullWidth
                margin="normal"
                defaultValue={dataRowEdit?.zipcode}
                {...register("postalCode", {
                    required: "Código postal es obligatorio",
                })}
                error={Boolean(errors.postalCode)}
                helperText={errors.postalCode?.message}
                sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
            />
            <Button
                onClick={handleZipcodeSearch}
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ backgroundColor: "#AD6C56", mt: 1, mb: 2 }}
            >
                Buscar
            </Button>

            {responseZipCode && (
                <>
                    <FormControl
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.state)}
                    >
                        <InputLabel>Estado</InputLabel>
                        <Select
                            {...register("state", {
                                required: "Estado es obligatorio",
                            })}
                            variant="outlined"
                            value={state}
                            onChange={handleStateChange}
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: "4px",
                            }}
                        >
                            <MenuItem value={responseZipCode.state}>
                                {responseZipCode.state}
                            </MenuItem>
                        </Select>
                        {errors.state && (
                            <p style={{ color: "red" }}>
                                {errors.state.message}
                            </p>
                        )}
                    </FormControl>
                    <FormControl
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.municipality)}
                    >
                        <InputLabel>Municipio</InputLabel>
                        <Select
                            {...register("municipality", {
                                required: "Municipio es obligatorio",
                            })}
                            variant="outlined"
                            value={municipality}
                            onChange={handleMunicipalityChange}
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: "4px",
                            }}
                        >
                            <MenuItem value={responseZipCode.municipality}>
                                {responseZipCode.municipality}
                            </MenuItem>
                        </Select>
                        {errors.municipality && (
                            <p style={{ color: "red" }}>
                                {errors.municipality.message}
                            </p>
                        )}
                    </FormControl>
                    <FormControl
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.neighborhood)}
                    >
                        <InputLabel>Colonia</InputLabel>
                        <Select
                            {...register("neighborhood", {
                                required: "Colonia es obligatoria",
                            })}
                            variant="outlined"
                            value={neighborhood}
                            onChange={handleNeighborhoodChange}
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: "4px",
                            }}
                        >
                            {responseZipCode?.neighborhoods?.map((neigh) => (
                                <MenuItem key={neigh.id} value={neigh.name}>
                                    {neigh.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.neighborhood && (
                            <p style={{ color: "red" }}>
                                {errors.neighborhood.message}
                            </p>
                        )}
                    </FormControl>
                    <TextField
                        label="Calle y número"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        defaultValue={dataRowEdit?.address}
                        {...register("street", {
                            required: "Calle y número son obligatorios",
                        })}
                        error={Boolean(errors.street)}
                        helperText={errors.street?.message}
                        sx={{
                            backgroundColor: "#fff",
                            borderRadius: "4px",
                        }}
                    />
                    <TextField
                        label="Referencia"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        defaultValue={dataRowEdit?.address_reference}
                        {...register("reference", {
                            required: "Referencia es obligatoria",
                        })}
                        error={Boolean(errors.reference)}
                        helperText={errors.reference?.message}
                        sx={{
                            backgroundColor: "#fff",
                            borderRadius: "4px",
                        }}
                    />
                </>
            )}
        </>
    );
};

export default StoreAddress;
