import { useEffect, useState } from "react";

export const useStoreAddress = ({ setValue, responseZipCode, dataRowEdit }) => {
    const [state, setState] = useState(dataRowEdit?.state || "");
    const [municipality, setMunicipality] = useState(
        dataRowEdit?.municipality || ""
    );
    const [neighborhood, setNeighborhood] = useState(
        dataRowEdit?.neighborhood || ""
    );

    useEffect(() => {
        // Actualiza los valores del formulario con la respuesta del código postal
        if (responseZipCode) {
            if (!state && responseZipCode.state) {
                setState(responseZipCode.state);
                setValue("state", responseZipCode.state);
            }
            if (!municipality && responseZipCode.municipality) {
                setMunicipality(responseZipCode.municipality);
                setValue("municipality", responseZipCode.municipality);
            }
            if (!neighborhood && responseZipCode.neighborhoods?.length > 0) {
                const firstNeighborhood = responseZipCode.neighborhoods[0].name;
                setNeighborhood(firstNeighborhood);
                setValue("neighborhood", firstNeighborhood);
            }
        }
    }, [responseZipCode, state, municipality, neighborhood, setValue]);

    const handleStateChange = (event) => {
        const value = event.target.value;
        setState(value);
        setValue("state", value);
    };

    const handleMunicipalityChange = (event) => {
        const value = event.target.value;
        setMunicipality(value);
        setValue("municipality", value);
    };

    const handleNeighborhoodChange = (event) => {
        const value = event.target.value;
        setNeighborhood(value);
        setValue("neighborhood", value);
    };

    return {
        state,
        municipality,
        neighborhood,
        handleStateChange,
        handleMunicipalityChange,
        handleNeighborhoodChange,
    };
};
