import React from "react";
import { connect } from "react-redux";
import FilterIpbDirectory from "./components/FilterIpbDirectory";
import { Box, Typography, Grid } from "@mui/material";
import { useIpbDirectoryHook } from "./hooks/useIpbDirectoryHook";
import CircularProgress from "@mui/material/CircularProgress";
import { CircularProgressStyle } from "./style/style";
import DataTable from "widgets/datagrid/DataTable";
import CustomDialog from "widgets/component/CustomDialog";
import StoreDetails from "utils/componentGenericStore/StoreDetails";
import { ipbDirectoryListingAction } from "_models/redux/ipb-directory-listing/action";
import { cleanIpbDirectoryListingAction } from "_models/redux/ipb-directory-listing/clean";
import { ipbDirectoryDetailAction } from "_models/redux/ipb-directory-detail/action";
import { cleanIpbDirectoryDetailAction } from "_models/redux/ipb-directory-detail/clean";
import AddForm from "./components/AddStore";

const PageIpbDirectoryListing = ({
    ipbDirectoryListingAction,
    responseIpbDirectoryListing,
    cleanIpbDirectoryListingAction,
    ipbDirectoryDetailAction,
    cleanIpbDirectoryDetailAction,
    responseIpbDirectoryDetail,
    responselistIsRegisteredStores,
    transactionId,
    onClose,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        handleCleanFilters,
        loading,
        page,
        size,
        pagination,
        mappedRows,
        handleRowClick,
        Columns,
        isDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        handleOpenAddStore,
        isDialogOpenStore,
        initialValues,
        dataRowEdit,
        selectedRowStore,
        setdataRowEdit,
    } = useIpbDirectoryHook({
        ipbDirectoryListingAction,
        responseIpbDirectoryListing,
        cleanIpbDirectoryListingAction,
        ipbDirectoryDetailAction,
        cleanIpbDirectoryDetailAction,
        responseIpbDirectoryDetail,
        responselistIsRegisteredStores,
        transactionId,
        onClose,
    });

    return (
        <Box>
            <Grid>
                <h4>Tiendas IPB</h4>
            </Grid>
            <Box sx={{ mb: 10 }}>
                <FilterIpbDirectory
                    handleOpenAddStore={handleOpenAddStore}
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    register={register}
                    onCleanFilters={handleCleanFilters}
                />
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </Box>

            <>
                {loading ? (
                    <CircularProgress
                        sx={CircularProgressStyle.CircularProgress}
                    />
                ) : (
                    <>
                        {mappedRows?.length > 0 ? (
                            <DataTable
                                isActive={"isActive"}
                                rows={mappedRows}
                                columns={Columns}
                                onRowClick={handleRowClick}
                                page={page}
                                pageSize={size}
                                rowCount={pagination?.total_rows ?? 0}
                                onPageChange={pagination?.handleChangePage}
                                handleChangePageSize={
                                    pagination?.handleChangePageSize
                                }
                            />
                        ) : (
                            <Typography variant="h6">
                                No hay registros disponibles
                            </Typography>
                        )}
                    </>
                )}
            </>
            <CustomDialog
                fullWidth
                title={
                    responseIpbDirectoryDetail?.response?.payment_info === null
                        ? "No hay datos"
                        : "Detalle de la tienda"
                }
                content={
                    loading ? (
                        <CircularProgress
                            sx={CircularProgressStyle.CircularProgress}
                        />
                    ) : (
                        <StoreDetails
                            storeData={responseIpbDirectoryDetail?.payload}
                        />
                    )
                }
                open={isDialogOpenDetails}
                onClose={handleClose}
                maxWidth="md"
            />

            <CustomDialog
                title="Mensaje"
                content={<p>{dialogMessage}</p>}
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />

            <CustomDialog
                title={initialValues ? "Editar tienda" : "Registra tu tienda"}
                content={
                    <AddForm
                        handleClose={handleClose}
                        setdataRowEdit={setdataRowEdit}
                        selectedRowStore={selectedRowStore}
                        dataRowEdit={dataRowEdit}
                        initialValues={initialValues}
                        responselistIsRegisteredStores={
                            responselistIsRegisteredStores
                        }
                    />
                }
                open={isDialogOpenStore}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                // extraButtons={[
                //     {
                //         label: "Aceptar",
                //         variant: "contained",
                //         onClick: handleClose,
                //         buttonColor: "purple",
                //         textColor: "white",
                //     },
                // ]}
            />
        </Box>
    );
};

const mapStateToProps = (state) => ({
    responseIpbDirectoryListing: state?.responseIpbDirectoryListing,
    responseIpbDirectoryDetail: state?.responseIpbDirectoryDetail,
});

const mapDispatchToProps = {
    ipbDirectoryListingAction,
    cleanIpbDirectoryListingAction,
    ipbDirectoryDetailAction,
    cleanIpbDirectoryDetailAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageIpbDirectoryListing);
