import axiosTokenInstance from "services/api/backoffice";
import {
    DIRECTORY_STORES_EXISTS,
    DIRECTORY_STORES_EXISTS_ERROR,
} from "_models/redux/types";

// Acción para verificar si una tienda existe
export const directoryStoresExistsAction = (validation) => async (dispatch) => {
    console.log("params (FormData)", validation);

    try {
        const response = await axiosTokenInstance.get(
            `/api/ipb/directory-stores/exists`,
            {
                params: validation,
            }
        );

        const { data, status } = response || {};

        // Despachar los datos de la respuesta
        dispatch({
            type: DIRECTORY_STORES_EXISTS,
            payload: data || {},
            status: status || {},
            method: "get",
        });
    } catch (e) {
        // Manejo de errores
        dispatch({
            type: DIRECTORY_STORES_EXISTS_ERROR,
            payload: e || {},
        });
    }
};
