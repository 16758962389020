import { connect } from "react-redux";
import { useAddStore } from "../hooks/useAddStore";
import { registeredStoresAction } from "_models/redux/register-mtc-id/action";
import { editStoresAction } from "_models/redux/edit-mtc-id/action";
import { cleanIsEditStores } from "_models/redux/edit-mtc-id/clean-action";
import { directoryStoresExistsAction } from "_models/redux/directory-stores-exists/action";
import { directoryStoresOmvsAction } from "_models/redux/directory-stores-omvs/action";
import { zipCodeAddressStoreAction } from "_models/redux/zipcode-address-store/action";
import { cleanZipCodeAddressStore } from "_models/redux/zipcode-address-store/clean-action";
import { cleanIsRegisteredStores } from "_models/redux/register-mtc-id/clean-action";
import AddStoreForm from "./StoreForm";

const AddStore = (props) => {
    const {
        initialValues,
        registeredStoresAction,
        cleanIsRegisteredStores,
        responseRegisteredStores,
        editStoresAction,
        responseEditStores,
        cleanIsEditStores,
        dataRowEdit,
        selectedRowStore,
        responseDirectoryStoreExists,
        directoryStoresExistsAction,
        responseDirectoryStoreOmvsReducer,
        directoryStoresOmvsAction,
        responseZipCodeAddressStoreReducer,
        zipCodeAddressStoreAction,
        cleanZipCodeAddressStore,
    } = props;

    const addStoreProps = useAddStore({
        initialValues,
        registeredStoresAction,
        responseRegisteredStores,
        editStoresAction,
        responseEditStores,
        cleanIsEditStores,
        selectedRowStore,
        responseDirectoryStoreExists,
        directoryStoresExistsAction,
        responseDirectoryStoreOmvsReducer,
        directoryStoresOmvsAction,
        dataRowEdit,
        zipCodeAddressStoreAction,
        responseZipCodeAddressStoreReducer,
        cleanZipCodeAddressStore,
        cleanIsRegisteredStores,
    });

    return <AddStoreForm {...addStoreProps} {...props} />;
};

const mapStateToProps = (state) => ({
    responseRegisteredStores: state?.responseRegisteredStores,
    responseEditStores: state?.responseEditStores,
    responseDirectoryStoreExists: state?.responseDirectoryStoreExists,
    responseDirectoryStoreOmvsReducer: state?.responseDirectoryStoreOmvsReducer,
    responseZipCodeAddressStoreReducer:
        state?.responseZipCodeAddressStoreReducer,
});

const mapDispatchToProps = {
    registeredStoresAction,
    cleanIsRegisteredStores,
    editStoresAction,
    cleanIsEditStores,
    directoryStoresExistsAction,
    directoryStoresOmvsAction,
    zipCodeAddressStoreAction,
    cleanZipCodeAddressStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStore);
