import axiosTokenInstance from "services/api/backoffice";
import {
    REGISTERED_STORES,
    REGISTERED_STORES_ERROR,
} from "_models/redux/types";

// Acción para registrar una tienda
export const registeredStoresAction = (formData) => async (dispatch) => {
    console.log("params (FormData)", formData); // Verifica que se esté enviando el FormData

    try {
        // Realiza la solicitud con `FormData` y usa `multipart/form-data`
        const response = await axiosTokenInstance.post(
            `/api/ipb/directory-stores`,
            formData, // Enviar `formData` directamente, no como objeto
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );

        const { data, status } = response || {};

        // Despachar los datos de la respuesta
        dispatch({
            type: REGISTERED_STORES,
            payload: data || {},
            status: status || {},
            method: "post",
        });
    } catch (e) {
        // Manejo de errores
        dispatch({
            type: REGISTERED_STORES_ERROR,
            payload: e || {},
        });
    }
};
