import {
    Avatar,
    Button,
    Checkbox,
    FormControlLabel,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { SelectHour } from "../select-hours-form/SelectHour";

export const SelectSchedule = ({ disabledDay, onSave, onCanceled }) => {
    const [is24Hours, setIs24Hours] = useState(false);
    const [openingHour, setOpeningHour] = useState("09:00");
    const [closingHour, setClosingHour] = useState("18:00");
    const [dayOfWeek, setDayOfWeek] = useState([
        {
            week_day: 1,
            day: "Lunes",
            letter: "L",
            isSelect: disabledDay?.includes(1),
            isDisabled: disabledDay?.includes(1),
        },
        {
            week_day: 2,
            day: "Martes",
            letter: "M",
            isSelect: disabledDay?.includes(2),
            isDisabled: disabledDay?.includes(2),
        },
        {
            week_day: 3,
            day: "Miércoles",
            letter: "M",
            isSelect: disabledDay?.includes(3),
            isDisabled: disabledDay?.includes(3),
        },
        {
            week_day: 4,
            day: "Jueves",
            letter: "J",
            isSelect: disabledDay?.includes(4),
            isDisabled: disabledDay?.includes(4),
        },
        {
            week_day: 5,
            day: "Viernes",
            letter: "V",
            isSelect: disabledDay?.includes(5),
            isDisabled: disabledDay?.includes(5),
        },
        {
            week_day: 6,
            day: "Sábado",
            letter: "S",
            isSelect: disabledDay?.includes(6),
            isDisabled: disabledDay?.includes(6),
        },
        {
            week_day: 7,
            day: "Domingo",
            letter: "D",
            isSelect: disabledDay?.includes(7),
            isDisabled: disabledDay?.includes(7),
        },
    ]);

    const handleSelectDay = (week_day) => {
        const newDayOfWeek = dayOfWeek.map((day) => {
            if (day.week_day === week_day) {
                return { ...day, isSelect: !day.isSelect };
            }

            return day;
        });
        setDayOfWeek(newDayOfWeek);
    };

    const handleSaveSchedule = () => {
        const selectedDays = dayOfWeek.filter(
            (day) => day.isSelect && !day.isDisabled
        );

        const convertDays = selectedDays.map((day) => ({
            week_day: day.week_day,
            day: day.day,
            letter: day.letter,
            openingHour,
            closingHour,
            is24Hours,
        }));
        onSave(convertDays);
    };

    const handleSetOpeningHour = (hour) => {
        setOpeningHour(hour);
    };

    const handleSetClosingHour = (hour) => {
        setClosingHour(hour);
    };

    return (
        <Stack direction={"column"} spacing={"20px"} p={1}>
            <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign={"center"}
            >
                Selecciona los días y el horario
            </Typography>
            <Stack
                spacing={{ xs: 1, md: 2 }}
                flexWrap={{ xs: "wrap", md: "nowrap" }}
                direction={"row"}
                justifyContent={{ xs: "space-around", md: "space-between" }}
            >
                {dayOfWeek.map(
                    ({ day, letter, week_day, isDisabled, isSelect }) => (
                        <Tooltip title={day} key={week_day}>
                            <Avatar
                                sx={{
                                    cursor: isDisabled
                                        ? "not-allowed"
                                        : "pointer",
                                    opacity: isDisabled ? 0.5 : 1,
                                    background: isSelect ? "#691C32" : "gray",
                                }}
                                onClick={() =>
                                    isDisabled ? {} : handleSelectDay(week_day)
                                }
                                disabled={isDisabled}
                            >
                                {letter}
                            </Avatar>
                        </Tooltip>
                    )
                )}
            </Stack>

            <FormControlLabel
                control={
                    <Checkbox
                        value={is24Hours}
                        onChange={() => setIs24Hours(!is24Hours)}
                    />
                }
                label="Abierto las 24 horas"
            />

            <Stack spacing={2} direction={"row"}>
                <SelectHour
                    initialHour={"09:00"}
                    prefix={"De"}
                    nameSelect={"openingHour"}
                    isDisabled={is24Hours}
                    onChange={handleSetOpeningHour}
                />
                <SelectHour
                    initialHour={"18:00"}
                    prefix={"A"}
                    nameSelect={"openingHour"}
                    isDisabled={is24Hours}
                    onChange={handleSetClosingHour}
                />
            </Stack>

            <Stack spacing={2} direction={"row"} justifyContent={"flex-end"}>
                <Button variant="contained" onClick={onCanceled}>
                    Cancelar
                </Button>
                <Button variant="contained" onClick={handleSaveSchedule}>
                    Guardar
                </Button>
            </Stack>
        </Stack>
    );
};
