import axiosTokenInstance from "services/api/backoffice";
import { EDIT_STORES, EDIT_STORES_ERROR } from "_models/redux/types";

// Acción para editar una tienda
export const editStoresAction =
    (formData, selectedRowStore) => async (dispatch) => {
        try {
            // Realiza la solicitud con `FormData` y usa `multipart/form-data`
            const response = await axiosTokenInstance.put(
                `/api/ipb/directory-stores/${selectedRowStore}/edit`,
                formData, // Enviar `formData` directamente, no como objeto
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            const { data, status } = response || {};

            // Despachar los datos de la respuesta
            dispatch({
                type: EDIT_STORES,
                payload: data || {},
                status: status || {},
                method: "put",
            });
        } catch (e) {
            // Manejo de errores
            dispatch({
                type: EDIT_STORES_ERROR,
                payload: e || {},
            });
        }
    };
