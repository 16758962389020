import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { cardStyles, typographyTitleStyles } from "../styles/styles";

const StoreInfo = ({ storeData, ActionStore, isRejecting }) => (
    <Grid item xs={12} sm={6}>
        <Card sx={cardStyles}>
            <CardContent>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={typographyTitleStyles}
                >
                    <strong>Tienda</strong>
                </Typography>
                <Typography variant="body1">
                    <strong>ID externo:</strong> {storeData.external_id}
                </Typography>
                <Typography variant="body1">
                    <strong>Nombre:</strong> {storeData.store_name}
                </Typography>
                <Typography variant="body1">
                    <strong>Aprobación:</strong>{" "}
                    {ActionStore === "success"
                        ? isRejecting
                            ? "rechazado"
                            : "aceptado"
                        : storeData?.review_status}
                </Typography>
            </CardContent>
        </Card>
    </Grid>
);

export default StoreInfo;
