import React from "react";
import { Grid, Typography } from "@mui/material";
import { DetailsToku } from "./data/DetailsToku";
import { DetailsSTP } from "./data/DetailsSTP";
import { getTransactionDetails } from "./data/DetailsNetPay";
import { getProductDetails } from "./data/DetailsHistoryPrepaid";
import { getContactDetails } from "./data/DetailsSimIpb";
import { getOrderInfoDetails } from "./data/DetailPurchase";
import { getBankDetails } from "./data/DetailsOpenPay";

const InfoRow = ({ label, value }) => (
    <Grid
        item
        xs={6}
        style={{
            marginTop: "8px",
            wordWrap: "break-word",
            overflow: "hidden",
            textOverflow: "ellipsis",
        }}
    >
        <Typography variant="body1">
            <span style={{ fontSize: "0.875rem" }}>{label}:</span>{" "}
            {value || "N/A"}
        </Typography>
    </Grid>
);

const OrderInfoGrid = ({ orderDetails, type, title }) => {
    const titleStyle = { color: "#7A3C92" };
    let infoItems = [];

    if (type === "detailsSTP") {
        infoItems = DetailsSTP(orderDetails);
    } else if (type === "detailsToku") {
        infoItems = DetailsToku(orderDetails);
    } else if (type === "detailsNetPay") {
        infoItems = getTransactionDetails(orderDetails);
    } else if (type === "detailsHistoryPrepaid") {
        infoItems = getProductDetails(orderDetails);
    } else if (type === "detailsSimIpb") {
        infoItems = getContactDetails(orderDetails);
    } else if (type === "detailPurchase") {
        infoItems = getOrderInfoDetails(orderDetails);
    } else if (type === "detailsOpenPay") {
        infoItems = getBankDetails(orderDetails);
    }
    return (
        <Grid
            container
            className="device-info"
            xl={12}
            style={{
                marginLeft: "40px",
                marginTop: "50px",
            }}
        >
            {type === "detailsSTP" && (
                <Grid item xs={12}>
                    <span style={titleStyle}>{title}</span>
                </Grid>
            )}
            {infoItems.map((item, index) => (
                <InfoRow key={index} label={item?.label} value={item?.value} />
            ))}
        </Grid>
    );
};

export default OrderInfoGrid;
