import {
    DIRECTORY_STORES_EXISTS,
    DIRECTORY_STORES_EXISTS_ERROR,
    CLEAN_DIRECTORY_STORES_EXISTS,
    CLEAN_DIRECTORY_STORES_EXISTS_ERROR,
} from "_models/redux/types";

const initialState = {
    loaded: undefined,
    method: undefined,
    payload: undefined,
    status: undefined,
    state: undefined,
};

export default function directoryStoreExistsReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case DIRECTORY_STORES_EXISTS:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "success",
            };

        case DIRECTORY_STORES_EXISTS_ERROR:
            return {
                ...state,
                loaded: true,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "error",
            };

        case CLEAN_DIRECTORY_STORES_EXISTS:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "success",
            };

        case CLEAN_DIRECTORY_STORES_EXISTS_ERROR:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "error",
            };
        default:
            return state;
    }
}
