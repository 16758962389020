import React from "react";
import { TextField } from "@mui/material";

const StoreContact = ({ register, errors, dataRowEdit }) => (
    <>
        <TextField
            label="Nombre de la tienda"
            variant="outlined"
            fullWidth
            margin="normal"
            defaultValue={dataRowEdit?.store_name || ""}
            {...register("storeName", {
                required: "Nombre de la tienda es obligatorio",
            })}
            error={Boolean(errors.storeName)}
            helperText={errors.storeName?.message}
            sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
        />
        <TextField
            label="Número telefónico"
            variant="outlined"
            fullWidth
            margin="normal"
            defaultValue={dataRowEdit?.phone_number || ""}
            {...register("phoneNumber", {
                required: "Número telefónico es obligatorio",
            })}
            error={Boolean(errors.phoneNumber)}
            helperText={errors.phoneNumber?.message}
            sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
        />
        <TextField
            label="Correo electrónico"
            variant="outlined"
            fullWidth
            margin="normal"
            defaultValue={dataRowEdit?.email || ""}
            {...register("email", {
                required: "Correo electrónico es obligatorio",
            })}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
        />
    </>
);

export default StoreContact;
