import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const StoreMap = ({ latitude, longitude, onLocationChange }) => {
    const defaultPosition = { lat: 23.634501, lng: -102.552784 }; // Centro de México
    const [markerPosition, setMarkerPosition] = useState(
        latitude && longitude ? { lat: latitude, lng: longitude } : null
    ); // Mostrar marcador solo si se proporcionan coordenadas
    const [zoom, setZoom] = useState(latitude && longitude ? 14 : 5); // Zoom predeterminado

    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    // Este hook cargará la API de Google Maps y manejará la carga correctamente.
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey, // Clave de API de Google Maps
    });

    useEffect(() => {
        // Si tienes coordenadas para el marcador, actualízalas
        if (latitude && longitude) {
            setMarkerPosition({ lat: latitude, lng: longitude });
            setZoom(14); // Ajustar el zoom para mostrar bien el lugar
        }
    }, [latitude, longitude]);

    const handleMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        setMarkerPosition({ lat: newLat, lng: newLng });
        onLocationChange(newLat, newLng);
    };

    const handleMapClick = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        setMarkerPosition({ lat: newLat, lng: newLng });
        onLocationChange(newLat, newLng);
        setZoom(14); // Ajustar el zoom cuando el usuario asigna una nueva ubicación
    };

    // Capturamos el error de carga si existe
    useEffect(() => {
        if (loadError) {
            console.error("Error al cargar la API de Google Maps:", loadError);
        }
    }, [loadError]);

    // Si la API no está cargada, mostramos un mensaje de carga
    if (!isLoaded) {
        return <div>Cargando mapa...</div>;
    }

    return (
        <GoogleMap
            mapContainerStyle={{ width: "100%", height: "400px" }}
            center={markerPosition || defaultPosition}
            zoom={zoom}
            onClick={handleMapClick}
        >
            {markerPosition && (
                <Marker
                    position={markerPosition}
                    draggable={true}
                    onDragEnd={handleMarkerDragEnd}
                />
            )}
        </GoogleMap>
    );
};

export default StoreMap;
