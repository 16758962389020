import React from "react";
import {
    TextField,
    Button,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    CircularProgress,
} from "@mui/material";

const StoreDetails = ({
    register,
    errors,
    handleSearchStore,
    omvs,
    loading,
    handleOmvChange,
    selectedOmv,
}) => {
    return (
        <>
            <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
                sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
            >
                <InputLabel id="omv-label">OMV</InputLabel>
                <Select
                    labelId="omv-label"
                    label="OMV"
                    {...register("omv", { required: "OMV es obligatorio" })}
                    error={Boolean(errors.omv)}
                    onChange={handleOmvChange}
                >
                    {omvs?.map((omv) => (
                        <MenuItem key={omv?.id} value={omv?.id}>
                            {omv?.name}
                        </MenuItem>
                    ))}
                </Select>
                {errors.omv && (
                    <p style={{ color: "red" }}>{errors.omv.message}</p>
                )}
            </FormControl>

            {selectedOmv === 283 && (
                <TextField
                    label="MTC ID"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    {...register("mtcId", {
                        required: "MTC ID es obligatorio",
                    })}
                    error={Boolean(errors.mtcId)}
                    helperText={errors.mtcId?.message}
                    sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
                />
            )}

            {selectedOmv && (
                <TextField
                    label="ID Establecimiento"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    {...register("establishmentId", {
                        required: "ID Establecimiento es obligatorio",
                    })}
                    error={Boolean(errors.establishmentId)}
                    helperText={errors.establishmentId?.message}
                    sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
                />
            )}

            <Button
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ backgroundColor: "#AD6C56", mt: 1, mb: 2 }}
                onClick={handleSearchStore}
            >
                Buscar tienda
            </Button>
            {loading && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                    }}
                >
                    <CircularProgress style={{ color: "#fff" }} />
                </div>
            )}
        </>
    );
};

export default StoreDetails;
